import React from "react";
import { graphql } from "gatsby"
import Layout from "../components/layout"


const AboutPage = ({ data }) => (
  <Layout>
    <div style={{ color: `teal`}}>
      <h1>About { data.site.siteMetadata.title }</h1>
      <p>I’m good enough, I’m smart enough, and gosh darn it, people like me!t</p>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default AboutPage